import React from "react";
import Button from "components/CustomButtons/Button.js";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link } from "react-router-dom";


export default function ToggleVisibilityOrder({ product }) {
  return (
    <>
   <Link to="/treatmentcartorder-page">
<Button round color="info"
    endIcon={<ArrowForwardIcon />}
>
ACHETER
</Button>
</Link>
  </>   



  );
}