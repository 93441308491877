/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ViewDay from "@material-ui/icons/ViewDay";
import Dns from "@material-ui/icons/Dns";
import Build from "@material-ui/icons/Build";
import ListIcon from "@material-ui/icons/List";
import People from "@material-ui/icons/People";
import Assignment from "@material-ui/icons/Assignment";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Chat from "@material-ui/icons/Chat";
import Call from "@material-ui/icons/Call";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccountBalance from "@material-ui/icons/AccountBalance";
import ArtTrack from "@material-ui/icons/ArtTrack";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import LocationOn from "@material-ui/icons/LocationOn";
import Fingerprint from "@material-ui/icons/Fingerprint";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Store from "@material-ui/icons/Store";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Layers from "@material-ui/icons/Layers";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import LineStyle from "@material-ui/icons/LineStyle";
import Error from "@material-ui/icons/Error";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {

  const n6_prv = () => {
    if (sessionStorage.getItem("n6_prv") === sessionStorage.getItem("id_clt") + '6') {
      return (<>
        <ListItem className={classes.listItem}>
          <Link to="/account-page" className={classes.navLink}>
            <Fingerprint className={classes.dropdownIcons} />
            Compte Principal
          </Link>
        </ListItem>
      </>)
    } else {
      <></>
    }
  };
  const n6_1prv = () => {
    if (sessionStorage.getItem("n6_prv") === sessionStorage.getItem("id_clt") + '6') {
      return (<>
        <ListItem className={classes.listItem}>
          <Link to="/productlist-page" className={classes.navLink}>
            <Build className={classes.dropdownIcons} />
            Produits
          </Link>
        </ListItem>
      </>)
    } else {
      <></>
    }
  };

  const n4_prv = () => {
    if (sessionStorage.getItem("n4_prv") === sessionStorage.getItem("id_clt") + '4') {
      return (<>
        <ListItem className={classes.listItem}>
          <Link to="/orderlist-page" className={classes.navLink}>
            <ViewDay className={classes.dropdownIcons} />
            Commandes
          </Link>
        </ListItem>
      </>)
    } else {
      <></>
    }
  };
  const n4_1prv = () => {
    if (sessionStorage.getItem("n4_prv") === sessionStorage.getItem("id_clt") + '4') {
      return (<>
        <ListItem className={classes.listItem}>
          <Link to="/createUsers-page" className={classes.navLink}>
            <AccountCircle className={classes.dropdownIcons} />
            Compte utilisateur
          </Link>
        </ListItem>
      </>)
    } else {
      <></>
    }
  };
  const n9_prv = () => {
    if (sessionStorage.getItem("n9_prv") === sessionStorage.getItem("id_clt") + '9') {
      return (<>
        <ListItem className={classes.listItem}>
          <Link to="/orderlistReport-page" className={classes.navLink}>
            <ViewQuilt className={classes.dropdownIcons} />
            Reporting
          </Link>
        </ListItem>
      </>)
    } else {
      <></>
    }
  };
  const n9_1prv = () => {
    if (sessionStorage.getItem("n9_prv") === sessionStorage.getItem("id_clt") + '9') {
      return (<>
        <ListItem className={classes.listItem}>
          <Link to="/orderlistFacturation-page" className={classes.navLink}>
            <AccountBalance className={classes.dropdownIcons} />
            Facturation
          </Link>
        </ListItem>
      </>)
    } else {
      <></>
    }
  };
  const n10_prv = () => {
    if (sessionStorage.getItem("n10_prv") === sessionStorage.getItem("id_clt") + '10') {
      return (<>
        <ListItem className={classes.listItem}>
          <Link to="/chargeArticle-page" className={classes.navLink}>
            <Store className={classes.dropdownIcons} />
            Gestion
          </Link>
        </ListItem>
      </>)
    } else {
      <></>
    }
  };


  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  if (sessionStorage.getItem("statut_clt") === 'modeAdmin') {
    return (
      <List className={classes.list + " " + classes.mlAuto}>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="ADMIN"
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            buttonIcon={Apps}
            dropdownList={[
              <Link to="/catalogue-page" className={classes.dropdownLink}>
                <Assignment className={classes.dropdownIcons} />
                CATALOGUE
              </Link>,
              n6_prv(),
              n6_1prv(),
              n4_prv(),
              n4_1prv(),
              n9_prv(),
              n9_1prv(),
              n10_prv()
            ]}
          />
        </ListItem>

        <List className={classes.list + " " + classes.mlAuto}>

          <ListItem className={classes.listItem}>
            <Link to="/logout-page" className={classes.navLink}>
              <ExitToAppIcon className={classes.icons} />
              DECONNEXION
            </Link>
          </ListItem>
         {/*  <ListItem className={classes.listItem}>
            <Link to="/treatmentcartorder-page" className={classes.navLink}>
              <ShoppingCart className={classes.icons} />
              PANIER
            </Link>
          </ListItem> */}
        </List>
      </List>
    );
  }
  else if (sessionStorage.getItem("statut_clt") === 'modeClient') {
    return (
      <List className={classes.list + " " + classes.mlAuto}>
        {n4_prv()}
        {n4_1prv()}
        {n9_prv()}
        {n9_1prv()}
        <ListItem className={classes.listItem}>
          <Link to="/catalogue-page" className={classes.navLink}>
            <Assignment className={classes.dropdownIcons} />
            CATALOGUE
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/logout-page" className={classes.navLink}>
            <ExitToAppIcon className={classes.dropdownIcons} />
            DECONNEXION
          </Link>
        </ListItem>
        {/* <ListItem className={classes.listItem}>
            <Link to="/treatmentcartorder-page" className={classes.navLink}>
              <ShoppingCart className={classes.icons} />
              PANIER
            </Link>
          </ListItem> */}
      </List>
    );
  }
  else {
    return (
      <List className={classes.list + " " + classes.mlAuto}>
        {/* <ListItem className={classes.listItem}>
                  <Link to="/components" className={classes.dropdownLink}>
              All components
            </Link>          
           </ListItem>  */}
        <ListItem className={classes.listItem}>
          <Link to="/inscription-page" className={classes.navLink}>
            <PersonAdd className={classes.dropdownIcons} />
            INSCRIPTION
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/conect-page" className={classes.navLink}>
            <AccountCircle className={classes.dropdownIcons} />
            CONNEXION
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Link to="/contact-page" className={classes.navLink}>
            <People className={classes.dropdownIcons} />
            CONTACT
          </Link>
        </ListItem>
       {/*  <ListItem className={classes.listItem}>
            <Link to="/treatmentcartorder-page" className={classes.navLink}>
              <ShoppingCart className={classes.icons} />
              PANIER
            </Link>
          </ListItem> */}
      </List>
    );
  }
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
